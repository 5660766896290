import * as React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/svgs/logo.svg";
import "./style.scss";
import { Spacer } from "../../styles";
import { TopNavRoundedButton } from "../../styles/styled";
import Aos from "aos";
import "aos/dist/aos.css";
export const Footer = () => {React.useEffect(() => {
  Aos.init({
    duration: 2000,
    mirror: true,
    once: false,
    offset: -300,
    delay: 300,
  });
}, []);
  return (
    <section
      className="footer-section  w-100 overflow-x-hidden  pad-alt m-0"
      id="footer"
    >
      <div className="slice-box d-none"></div>
      <div className="container-fluid  p-0 py-3 px-3 px-md-5 ">
        <Spacer height="70px" heightSm="30px" />
        <div className="row mb-3">
          <div className="col">
            {" "}
            <h1 className="text-center w-70 mx-auto text-white fs-55">
              {" "}
              Don't let your online presence fall behind.
            </h1>
            <TopNavRoundedButton
              className="btn boxer mt-5 mx-auto text-center text-capitalize"
              href="/contact"
              data-aos="zoom-in"
            >
              Register
            </TopNavRoundedButton>
          </div>
        </div>
        <div className="row m-0  justify-content-between justify-md-content-around pb-5">
          <div className="col-md-3 col-12 p-0 ">
            <Link to="/">
              <img className="img-fluid ml-neg-sm" src={logo} />
            </Link>{" "}
            <ul className="footerlist ">
              <li>
                <Link to="/"> No 53 Nnobi Street Surulere.</Link>
              </li>
              <li>
                <Link to="/">All Rights Reserved</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-auto col-6 p-0">
            {" "}
            <Spacer height="150px" heightSm="0px" />
            <h4 className="footer-title">Links</h4>
            <ul className="footerlist">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a href="/#aboutSection">About</a>
              </li>

              <li>
                <a href="/#stepsSection">Services</a>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-auto col-6 p-0">
            {" "}
            <Spacer height="150px" heightSm="0px" />
            <h4 className="footer-title">Company</h4>
            <ul className="footerlist">
              <li>
                <Link to="/">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>

              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-auto col-6 p-0">
            {" "}
            <Spacer height="150px" heightSm="0px" />
            <h4 className="footer-title">Get in touch</h4>
            <ul className="footerlist">
              <li>
                <a> No 53 Nnobi Street Surulere.</a>
              </li>
              <li>
                <a href="mailto:info@techiasystem.ng">info@techiasystem.ng</a>
              </li>
              <li>
                <a href="tel:+2348028922759">+234 802 892 2759</a>
              </li>
            </ul>
          </div>
        </div>
        <Spacer height="50px" heightSm="50px" />
      </div>
    </section>
  );
};
