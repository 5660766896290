import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { TopNavLinkAltStyled, TopNavLinkStyled, TopNavRoundedButton } from "../../styles/styled";
import line from "../../assets/svgs/nav-line.svg";
import broken from "../../assets/svgs/nav-broken.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export const Navbar = () => {
  const [toggle, setToggle] = React.useState(false);
  const NavToggler = () => {
    setToggle(!toggle);
    // console.log("toggle activated", toggle);
  };
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
    // console.log("toggle check", toggle);

    if (toggle) {
      document.body.style.overflowY = "hidden";
      // console.log("toggle check entered hidden", toggle);
    } else {
      document.body.style.overflowY = "auto";
      // console.log("toggle check entered else", toggle);
    }
  });

  React.useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) {
        document.body.style.overflowY = "auto";
        setToggle(false);
      }
    });
  });
  return (
    <>
      <nav
        id="topNav"
        className={toggle ? "nav-section open pad-alt" : "nav-section pad-alt"}
      >
        <div className="container-fluid  ">
          <div className="row justify-content-between">
            <div
              className="col-auto d-flex align-items-center "
              data-aos="fade-right"
            >
              <Link
                to="/"
                className={toggle ? " d-hide-sm logo-icon" : "logo-icon"}
              >
                <img className="img-fluid img-ctn" src={logo} />
              </Link>
            </div>
            <div className="col-md-5 d-flex align-items-center justify-content-center d-hide-sm">
              <ul className="navlist-lg " data-aos="fade-right">
                <li>
                  <TopNavLinkAltStyled href="/" className="active">
                    Home
                  </TopNavLinkAltStyled>
                </li>
                <li>
                  <TopNavLinkAltStyled href="/#aboutSection">
                    About
                  </TopNavLinkAltStyled>
                </li>
                <li>
                  <TopNavLinkAltStyled href="/#stepsSection">
                    Services
                  </TopNavLinkAltStyled>
                </li>
                <li>
                  <TopNavLinkStyled
                    to="/contact"
                    className="text-base green-text"
                  >
                    Contact US
                  </TopNavLinkStyled>
                </li>
              </ul>
            </div>
            <div
              data-aos="fade-left"
              
              className="col-auto d-hide-sm d-flex 
             align-items-center"
            >
              <TopNavRoundedButton href="/contact" className="btn boxer">
                Book an Appointment
              </TopNavRoundedButton>
            </div>
            <div className="col-auto d-flex align-items-center d-hide-lg justify-content-end ">
              {" "}
              <button
                onClick={() => {
                  NavToggler();
                }}
                className={
                  toggle ? "nav-toggler btn open mt-5" : "nav-toggler btn "
                }
              >
                <img src={line} alt="" />
                <img src={broken} alt="" />
                <img src={line} alt="" />
              </button>
            </div>
            <div
              className={
                toggle
                  ? "col-12  d-hide-lg h-100 px-4 py-5 navlist-sm-ctn open h-80"
                  : "col-12  d-hide-lg h-100 px-4 py-5 navlist-sm-ctn "
              }
            >
              <ul className="navlist-sm my-auto">
                <li>
                  <TopNavLinkAltStyled href="#topNav" className="active">
                    Home
                  </TopNavLinkAltStyled>
                </li>
                <li>
                  <TopNavLinkAltStyled href="#footer">
                    About
                  </TopNavLinkAltStyled>
                </li>
                <li>
                  <TopNavLinkAltStyled href="#stepsSection">
                    Services
                  </TopNavLinkAltStyled>
                </li>{" "}
                <li>
                  <TopNavLinkStyled to="/contact" className="green-text">
                    Contact US
                  </TopNavLinkStyled>
                </li>
                <li>
                  {" "}
                  <TopNavRoundedButton
                    className="btn mx-auto boxer w-100 max-unset"
                    href="/contact"
                  >
                    Book an Appointment
                  </TopNavRoundedButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
