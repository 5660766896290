import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import {
  IconTextStyledProp,
  ImageCardStyledProp,
  SpacerStyledProp,
  UnitStepCardStyledProp,
  VerticalSpacerStyledProp,
} from "./interface";

export const TopNavLinkStyled = styled(NavLink)`
  font-weight: 500;
  font-size: 16px;
  color: #303030;
  text-decoration: none;
  padding: 5px;
  :hover {
    color: #0ac05e;
  }
  &.active {
    color: #0ac05e;
  }
`;
export const TopNavLinkAltStyled = styled.a`
  font-weight: 500;
  font-size: 16px;
  color: #313131;
  text-decoration: none;
  transition: 0.4s ease;
  padding: 5px 10px;
  :active {
    ccolor: #313131;
  }
  :hover {
    color: #313131;
  }
  &.active {
    color: #313131;
  }
`;
export const TopNavRoundedButton = styled.a`
  font-weight: 500;
  max-width: 230px;
  height: 52px;
  padding: 0 3.4rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: #0ac05e;
  border: 2px solid #0ac05e;
  box-shadow: 0px 100px 80px rgba(56, 56, 56, 0.1),
    0px 64.8148px 46.8519px rgba(56, 56, 56, 0.0759259),
    0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407),
    0px 20px 13px rgba(56, 56, 56, 0.05),
    0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593),
    0px 1.85185px 3.14815px rgba(56, 56, 56, 0.0240741);
  border-radius: 5px;
  transition: 0.3s ease;
  justify-content: center;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  min-width: 170px;
  white-space: nowrap;
  text-transform: uppercase;

  :hover {
    background: #09d267;
    border-color: #09d267;
  }
  @media (max-width: 768px) {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    height: 45px;

    padding: unset;
  }
`;
export const HeroCaption = styled.h1`
  font-weight: 700;
  font-size: 60px;
  color: #000000;
  position: relative;
  font-family: var(--bold);
  // height: 150px;
  line-height: 89px;
  overflow: hidden;
  @media (max-width: 1121px) {
    // font-size: 45px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 42px;
  }
`;
export const HeroText = styled.h5`
  font-weight: 400;
  font-size: 17px;
  color: #545454;
  max-width: 600px;
  line-height: 24px;
  @media (max-width: 1030px) {
    font-size: 17px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
    max-width: unset;
    line-height: 28px;
  }
`;
export const HeroTextLg = styled.h5`
  font-weight: 400;
  font-size: 22px;
  color: #545454;
  max-width: 600px;
  line-height: 30px;
  @media (max-width: 1030px) {
    font-size: 20.9769px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 17px;
    max-width: unset;
    line-height: 28px;
    text-align: center;
  }
`;
export const HeroImgCardStyled = styled.img`
  width: var(--imgWidthLg);
  height: calc(
    var(--imgWidthLg) *
      ${({ heightCoefficient = 1.19 }: ImageCardStyledProp) =>
        heightCoefficient}
  );
  background: #a9a9a9;
  margin: 17px;
  border-radius: 16.1671px;
  @media (max-width: 1030px) {
    height: calc(
      var(--imgWidthMd) *
        ${({ heightCoefficient = 1.19 }: ImageCardStyledProp) =>
          heightCoefficient}
    );
    margin: 22px;
    width: var(--imgWidthMd) px;
    border-radius: 21.9797px;
  }
  @media (max-width: 768px) {
    border-radius: 10.1637px;
    margin: 10px;
    height: calc(
      var(--imgWidthSm) *
        ${({ heightCoefficient = 1.19 }: ImageCardStyledProp) =>
          heightCoefficient}
    );
    width: var(--imgWidthSm);
  }
`;
export const PurpleSectionTitle = styled.h2`
  font-weight: 700;
  font-size: 36px;
  color: #6a2381;
  font-family: var(--bold);
  text-align: center;
  @media (max-width: 992px) {
    font-size: 34px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const SectionSubTitle = styled.h5`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  line-height: 30px;
  color: #303030;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 25px;
  }
`;
export const IconTextStyled = styled.div`
  margin: 10px;
  width: fit-content;
  .icon {
    background-color: ${({ bgColor = "#7B61FF1A" }: IconTextStyledProp) =>
      bgColor};
    display: flex;

    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 20px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  display: flex;
  align-items: center;
  p {
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    color: #303030;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .icon {
      width: 90px;
      height: 90px;
      margin: 0;
    }
    p {
      font-size: 18px;

      font-weight: 700;
      margin-top: 20px;
    }
  }
`;
export const SpacerStyled = styled.div`
  height: ${({ height = "30px" }: SpacerStyledProp) => height} !important;
  display: block;
  @media (max-width: 768px) {
    height: ${({ heightSm = "0px" }: SpacerStyledProp) => heightSm} !important;
  }
`;
export const VerticalSpacerStyled = styled.div`
  width: ${({ width = "30px" }: VerticalSpacerStyledProp) => width};
  display: block;
  @media (max-width: 768px) {
    width: ${({ widthSm = "0px" }: VerticalSpacerStyledProp) => widthSm};
  }
`;
export const DarkHeaderText = styled.h3`
  font-weight: 700;
  font-size: 40px;
  color: #545454;
  text-align: center;
  font-family: var(--bold);
  @media (max-width: 1030px) {
    font-size: 32.4167px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
export const DarkHeaderTextMd = styled.h3`
  font-weight: 700;
  font-size: 32px;
  color: #545454;
  text-align: center;
  margin: 0;
  font-family: var(--bold);
  @media (max-width: 1030px) {
    font-size: 29px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const HeroTopStyled = styled.section`
  background-size: cover;
  background-repeat: no-repeat;
  // background-image: url("herogeneric");
  background-position: center;
  .map-bg-ctn {
    background-size: 90%;
    background-repeat: no-repeat;
    // background-image: url("mapbg");
    background-position: center;
  }
  .hero-header {
    font-size: 54px;
    text-align: center;
    text-transform: capitalize;

    color: #6a2381;
    font-weight: 700;
    font-family: var(--bold);
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
  .hero-subtext {
    font-size: 23px;
    color: #545454;
    text-align: center;
    line-height: 30px;

    font-size: 23.04px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
export const ErrorText = styled.p`
  color: #df3515;
  padding-left: 15px;
  margin-bottom: 0px;
  font-size: 13px;
`;
export const P14 = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #303030;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    text-align: center;
  }
`;
export const Loader = styled.span`
  width: fit-content;
  margin: 0;
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 22.5px;
    height: 22.5px;
  }
  .lds-spinner div {
    transform-origin: 11.25px 11.25px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0.825px;
    left: 10.62px;
    width: 1.6875px;
    height: 5px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const UnitCarouselImgStyled = styled.div`

// height:162px;
// width:202px;
position:relative;
// display:flex;
margin-right:30px;
:last-child{
  margin-right:0px;
}
align-items-center;
img{
  display:block;
  // width:202px;
}
`;
export const UnitStepCardStyled = styled.div`
  height: 100%;
  .stepcard_ctn {
    position: relative;
    height: 100%;
    background: #fff;
    border-radius: 20px;
    min-height: 380px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .header_ctn {
    background-color: #1ab7590d;
    height: 5rem;
    width: 5rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    display: flex;
  }
  h3 {
    font-size: 1.3rem;
    margin: 0;
  }

  .main_card {
    height: 100%;
    p {
      color: #757095;
      font-size: 1rem;
      line-height: 30px;
      margin: 0;
    }
  }
`;
