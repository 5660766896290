import * as React from "react";
import { Component } from "react";
import { useNavigate } from "react-router";
import { ImageCardStyledProp } from "./interface";
import Aos from "aos";
import "aos/dist/aos.css";
import arrowRight from "../assets/svgs/arrow_right.svg";
import {
  HeroImgCardStyled,
  HeroTopStyled,
  IconTextStyled,
  Loader,
  SpacerStyled,
  TopNavLinkStyled,
  UnitCarouselImgStyled,
  UnitStepCardStyled,
  VerticalSpacerStyled,
} from "./styled";

interface HeroImgCardProp {
  heightCoefficient?: number;
  className?: string;
  img?: string;
}
interface TopNavLinkProp {
  to?: string | undefined;
  activeClassName?: string | undefined;
  className?: string | undefined;
  children?: string;
}
interface IconTextProp {
  bgColor?: string;
  img?: string;
  text?: string;
  className?: string;
}
interface SpacerProp {
  height?: string;
  heightSm?: string;
  className?: string;
}
interface VerticalSpacerProp {
  width?: string;
  widthSm?: string;
  className?: string;
}
export const HeroImgCard: React.FC<HeroImgCardProp> = ({
  heightCoefficient,
  img,
  className,
  ...restProps
}) => {
  return (
    <HeroImgCardStyled
      src={img}
      heightCoefficient={heightCoefficient}
      className={className}
      {...restProps}
    ></HeroImgCardStyled>
  );
};
// export const TopNavLink: React.FC<TopNavLinkProp> = ({
//   to,
//   activeClassName,
//   className,
//   children,
//   ...restProps
// }) => {
//   return (
//     <TopNavLinkStyled
//       to={to}
//       className={className}
//       activeClassName={activeClassName}
//       {...restProps}
//     >
//       {children}
//     </TopNavLinkStyled>
//   );
// };
export const IconText: React.FC<IconTextProp> = ({
  img,
  bgColor,
  className,
  text,
  ...restProps
}) => {
  return (
    <IconTextStyled bgColor={bgColor} className={className} {...restProps}>
      <div className="icon">
        <img src={img} />
      </div>
      <p>{text}</p>
    </IconTextStyled>
  );
};

export const Spacer: React.FC<SpacerProp> = ({
  heightSm,
  height,
  className,
  ...restProps
}) => {
  return (
    <SpacerStyled
      height={height}
      heightSm={heightSm}
      className={className}
      {...restProps}
    ></SpacerStyled>
  );
};

export const VerticalSpacer: React.FC<VerticalSpacerProp> = ({
  widthSm,
  width,
  className,
  ...restProps
}) => {
  return (
    <VerticalSpacerStyled
      width={width}
      widthSm={widthSm}
      className={className}
      {...restProps}
    ></VerticalSpacerStyled>
  );
};
export const SpinnerLoader = ({ ...restProps }) => {
  return (
    <Loader {...restProps}>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Loader>
  );
};
export const UnitCarouselImg: React.FC<any> = ({ img, ...restProps }) => {
  return (
    <UnitCarouselImgStyled {...restProps}>
      <img className="img-fluid" src={img} />
    </UnitCarouselImgStyled>
  );
};
export const UnitStepCard: React.FC<any> = ({
  img,
  bgColor,
  title,
  text,
  ...restProps
}) => {
  return (
    <UnitStepCardStyled {...restProps} bgColor={bgColor}>
      <div className="stepcard_ctn p-4">
        <div className="top_side">
          <div className="header_ctn mb-4">
            <img src={img} />
          </div>
          <div className="main_card">
            <h3 className="fw-700">{title}</h3>{" "}
            <Spacer height={"1rem"} heightSm={"1rem"} />
            <p>{text}</p>
          </div>
        </div>
        <a className="green-text fw-500 underline-none">Read More <img className="ms-2" src={arrowRight}/></a>
      </div>
    </UnitStepCardStyled>
  );
};
