import React, { Component } from "react";
import {
  HeroCaption,
  HeroText,
  TopNavRoundedButton,
} from "../../styles/styled";
import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer } from "../../styles";
import heroBanner from "../../assets/images/hero_banner.png";

export const HomeHeroSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      {" "}
      <section className="home-hero-section  w-100 overflow-x-hidden pad-alt pe-md-0">
        <div className="container-fluid  p-0 pt-1 ">
          <Spacer height="00px" heightSm="0px" />
          <div className="top-hero position-relative pad-mini">
            <div className="row w-100 p-0 m-0 position-relative">
              <div className="col-md-7 px-xs-0 pe-md-0" data-aos="fade-right">
                <Spacer height="30px" heightSm="0px" />
                <HeroCaption className="pt-2">
                  Unlock your <span className="blue-border me-2">Business</span>{" "}
                  <span className="blue-border me-2">growth</span> with digital
                  Marketing
                </HeroCaption>
                <Spacer height={"0px"} heightSm={"30px"} />
                <HeroText>
                  We offer a variety of services for companies looking to gain
                  visibility in the digital marketing space. Including SMS
                  messaging, Email Marketing, Chatbots , Social media management
                  and online advertising, chatbots, and more.
                </HeroText>{" "}
                <Spacer height={"20px"} heightSm={"20px"} />
                <div className="d-md-flex align-items-center  justify-content-md-start justify-content-lg-start ">
                  <TopNavRoundedButton
                    className="btn boxer mx-md-0"
                    href="/contact"
                  >
                    Get Started
                  </TopNavRoundedButton>
                </div>
                <Spacer height={"90px"} heightSm={"30px"} />
              </div>
              <div className="col-md-5 p-0" data-aos="fade-up">
                <Spacer height={"70px"} heightSm={"30px"} />
                <img
                  src={heroBanner}
                  className="img-fluid img-banner  ml-neg"
                />
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
