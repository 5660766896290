import * as React from "react";
import { Component } from "react";
import "./style.scss";
import { Spacer, VerticalSpacer } from "../../styles";
import Aos from "aos";
import "aos/dist/aos.css";

import { Navbar } from "../../sections/navbar/navbar";
import { HomeHeroSection } from "../../sections/homeherosection/homeherosection";
import { StepSection } from "../../sections/stepsection/stepsection";
import { AboutSection } from "../../sections/aboutsection/aboutsection";
import { ServicesSection } from "../../sections/servicessection/servicessection";
import { NewsletterSection } from "../../sections/newslettersection/newslettersection";
import { Footer } from "../../sections/footer/footer";

export const HomePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <main className="homepage w-100 overflow-x-hidden">
        <div className="top-section px-md-4 px-0">
          <div className="circle-blur"></div>
          <Navbar />
          <HomeHeroSection /> <Spacer height={"50px"} heightSm={"50px"} />
        </div>
        <Spacer height={"40px"} heightSm={"50px"} />
        <StepSection />
        <AboutSection />
        <ServicesSection />
        <NewsletterSection />
        <Footer />
      </main>
    </>
  );
};
