import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer, UnitStepCard } from "../../styles";
import { HeroText, HeroTextLg, TopNavRoundedButton } from "../../styles/styled";
import boxIcon from "../../assets/svgs/package.svg";
import bgAbout from "../../assets/images/about_banner.png";
export const AboutSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section
        className="about-section  w-100 overflow-x-hidden "
        id="aboutSection"
      >
        <div className="row w-100 overflow-x-hidden justify-content-start ">
          {" "}
          <div className="col-md-auto  col-lg-7 pe-md-0 ">
            <div className="img_ctn">
              {" "}
              <img src={bgAbout} className="img-fluid" />
            </div>
          </div>
          <div className="col-md-8 col-lg-5 ps-md-0 pe-md-5 ps-md-0 " >
            {" "}
            <div
              className="px-4 px-md-0"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <Spacer height={"100px"} heightSm={"100px"} />
              <h5 className=" header">
                ABOUT{" "}
                <span
                  className="blue-border"
                  data-aos="zoom-in-right"
                  data-aos-delay="900"
                  
                >
                  US
                </span>
              </h5>
              <HeroText className=" mt-4">
                We are a digital marketing agency based in Lagos, Nigeria.We
                offer a variety of services for companies looking to gain
                visibility in the digital marketing space. We specialize in
                social media management, online advertising and chatbots. We can
                help your brand grow by increasing brand awareness, boosting
                sales and generating leads.
                <br />
                <br />
                Our team will not only help you develop a strategy for your
                social media presence and online advertising campaign, but also
                ensure that it is implemented effectively by setting up weekly
                reports and creating analytics tools that provide real-time data
                about your online activities.
              </HeroText>{" "}
              <TopNavRoundedButton
                className="btn boxer mx-md-0 mt-5"
                href="/contact"
              >
                Get Started
              </TopNavRoundedButton>
              <Spacer height={"50px"} heightSm={"50px"} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
