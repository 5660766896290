import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer, UnitStepCard } from "../../styles";
import { HeroText, HeroTextLg } from "../../styles/styled";
import boxIcon from "../../assets/svgs/package.svg";
import calendar from "../../assets/svgs/calendar.svg";import stock from "../../assets/svgs/stock.svg";
export const StepSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section
        className="steps-section  w-100 overflow-x-hidden pad-alt pb-5"
        id="stepsSection"
      >
        <Spacer height={"150px"} heightSm={"150px"} />
        <h5 className="w-80 text-center mx-auto header" data-aos="fade-up">
          We help your business grow faster.
        </h5>
        <HeroText
          className="w-60 text-center mx-auto mt-3"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Are you ready to start generating leads, driving sales, and increasing
          brand awareness?
        </HeroText>{" "}
        <div className="w-100  row m-0 gx-3 gx-md-6 gx-lg-5 gy-4">
          <Spacer height={"70px"} heightSm={"10px"} className="m-0" />
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
            <UnitStepCard
              title={"Choose Packages "}
              img={boxIcon}
              text={
                "Choose any of our packages that  suits and meet your business requirement. "
              }
            />
          </div>{" "}
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="500">
            <UnitStepCard
              title={"Schedule Appointment"}
              img={calendar}
              text={
                "Schedule an appointment with one of our specialist to discuss your business needs and services you want. "
              }
            />
          </div>{" "}
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="700">
            <UnitStepCard
              title={"Grow Together"}
              img={stock}
              text={
                "Grow Together with our team. Our utmost desire to create positive change in our business, and celebrate our amazing work. "
              }
            />
          </div>{" "}
          <Spacer height={"90px"} heightSm={"0px"} />
        </div>
      </section>
    </>
  );
};
