import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { ContactPage } from "./pages/contactpage/contactpage";
import { HomePage } from "./pages/homepage/homepage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />

          <Route
            path="/*"
            element={
              <Routes>
                <Route path="/contact" element={<ContactPage />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
