import React, { Component } from "react";
import {
  HeroCaption,
  HeroText,
  TopNavRoundedButton,
} from "../../styles/styled";
import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer } from "../../styles";
import location from "../../assets/svgs/location_icon.svg";

import phone from "../../assets/svgs/phone_icon.svg";
import pc_user from "../../assets/images/pc_user.png";
import email from "../../assets/svgs/email_icon.svg";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { GoogleMapComponent } from "../../helper/googlemaps/googlemaps";
const center = {
  lat: 6.709583,
  lng: 3.64484,
};
const containerStyle = {
  width: "100%",
  height: "400px",
};

export const ContactHeroSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      {" "}
      <section className="contact-hero-section  w-100 overflow-x-hidden ">
        <div className="container-fluid  p-0 pt-1 ">
          <Spacer height="00px" heightSm="0px" />
          <div className="top-hero position-relative pad-mini">
            <div className="row w-100 p-0 m-0 position-relative hero_bg">
              <Spacer height={"90px"} heightSm={"60px"} />
              <div className="col-md-6 ps-0"></div>
              <div className="col-md-5 ps-md-0">
                <HeroCaption className="pt-2">
                  Get <span className="blue-border me-2">in Touch</span> with Us
                  Today!{" "}
                </HeroCaption>
                <Spacer height={"30px"} heightSm={"10px"} />
                <HeroText className="text-dark">
                  Let's Connect and Grow Your Business, we are here to help you
                  succeed. Kindly fill out the form and our experts will be in
                  touch directly.
                </HeroText>{" "}
                <Spacer height={"30px"} heightSm={"20px"} />
                <TopNavRoundedButton
                  className="btn boxer mx-md-0"
                  href="/contact"
                >
                  Contact Us
                </TopNavRoundedButton>
                <Spacer height={"90px"} heightSm={"60px"} />
              </div>
            </div>
            <div className="row px-md-5 p-3 w-100">
              <div className="col-md-5">
                <Spacer height={"50px"} heightSm={"20px"} />
                <h1 className="fw-600">Our Location</h1>
                <br />{" "}
                <div
                  className="me-md-3  overflow-hidden"
                  style={{ borderRadius: "10px" }}
                >
                  {/* <GoogleMapComponent /> */}
                </div>
                {/* <Spacer height={"20px"} heightSm={"20px"} /> */}
                <div>
                  {/* <h5 className="fw-600">Nigeria</h5> */}
                  {/* <br /> */}
                  <a className="d-block text-dark underline-none d-flex align-items-top mb-3">
                    <img src={location} className="me-2" /> No 53 Nnobi Street
                    Surulere. <br />
                    Lagos, nigeria
                  </a>{" "}
                  <a
                    className="d-block text-dark underline-none d-flex align-items-top mb-3"
                    href="tel:+2348028922759"
                  >
                    <img src={phone} className="me-2" /> +234 802 892 2759
                  </a>
                  <a
                    className="d-block text-dark underline-none d-flex align-items-top mb-3"
                    href="mailto:info@techiasystem.ng"
                  >
                    <img src={email} className="me-2" /> info@techiasystem.ng
                  </a>
                </div>{" "}
                <Spacer height={"50px"} heightSm={"40px"} />
                <img src={pc_user} className="img-fluid img_girl" />{" "}
                <Spacer height={"70px"} heightSm={"70px"} />
              </div>
              <div className="col-md-7 p-0" data-aos="fade-up">
                <div className="p-3 p-md-5 ">
                  <form className="row   g-4">
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">First Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Last Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Email Address</label>
                        <input type="email" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Country</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Company Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Website </label>
                        <input type="url" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Industry</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-block">
                        <label htmlFor="">Mobile Number</label>
                        <input type="phone" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group d-block">
                        <label htmlFor="">Description</label>
                        <textarea className="form-control"></textarea>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-end pb-3 pb-md-5">
                      {" "}
                      <TopNavRoundedButton className="btn boxer  mt-md-5 ms-auto text-center text-capitalize shadow-none">
                        Submit
                      </TopNavRoundedButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
