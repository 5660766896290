import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer, UnitStepCard } from "../../styles";
import { HeroText, HeroTextLg, TopNavRoundedButton } from "../../styles/styled";

export const NewsletterSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="newsletter-section  w-100 overflow-x-hidden pad-alt">
        <div className="row justify-content-center">
          {" "}
          <Spacer height={"90px"} heightSm={"60px"} />
          <div className="col-md-9" data-aos="fade-down">
            <h3 className="bold-text  text-center fs-40 mb-4">
              Ready to get started?
            </h3>
            <HeroText className="bold-text mx-auto  text-center fs-20">
              If you're ready to take your marketing efforts to the next level,
              Register on our platform today and see the results yourself!
            </HeroText>
          </div>{" "}
          <Spacer height={"50px"} heightSm={"30px"} />
          <div className="col-md-6 col-11">
            <div className="signup_input_ctn" data-aos="zoom-in">
              <input
                className="form-control"

                placeholder="Enter you email Address"
              />{" "}
              <div>
                {" "}
                <TopNavRoundedButton className="btn boxer mx-auto mx-md-0">
                  Register
                </TopNavRoundedButton>
              </div>
            </div>
          </div>{" "}
          <Spacer height={"130px"} heightSm={"100px"} />
        </div>
      </section>
    </>
  );
};
