import * as React from "react";
import { Component } from "react";
import "./style.scss";
import { Spacer, VerticalSpacer } from "../../styles";
import Aos from "aos";
import "aos/dist/aos.css";

import { Navbar } from "../../sections/navbar/navbar";
import { Footer } from "../../sections/footer/footer";
import { ContactHeroSection } from "../../sections/contactherosection/contactherosection";

export const ContactPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <main className="contactpage w-100 overflow-x-hidden">
        <div className="top-section  px-0">
          {/* <div className="circle-blur"></div> */}
          <Navbar />
          <ContactHeroSection />
        </div>
        <Spacer height={"160px"} heightSm={"50px"} />

        <Footer />
      </main>
    </>
  );
};
