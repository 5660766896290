import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer, UnitStepCard } from "../../styles";
import { HeroText, HeroTextLg } from "../../styles/styled";
import sms from "../../assets/svgs/sms.svg";
import email from "../../assets/svgs/email.svg";
import stock from "../../assets/svgs/stock.svg";
import media from "../../assets/svgs/social_media.svg";
import search from "../../assets/svgs/search_engine.svg";
import analysis from "../../assets/svgs/analysis.svg";
import campaign from "../../assets/svgs/campaign.svg";
export const ServicesSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
      once: false,
      offset: -100,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section
        className="services-section  w-100 overflow-x-hidden pad-alt pb-5"
        id="servicesSection"
      >
        <Spacer height={"100px"} heightSm={"100px"} />
        <h5 className="w-50 text-center mx-auto header" data-aos="fade-up">
          <span className="blue-border">Save time</span> managing your business
          with our best Services
        </h5>

        <div className="w-100  row m-0 gx-3 gx-md-6 gx-lg-5 gy-4">
          <Spacer height={"70px"} heightSm={"10px"} className="m-0" />
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
            <UnitStepCard
              title={"SMS Messaging "}
              img={sms}
              text={
                "Letter of on become he tended active enable to. Vicinity relation sensible sociable surprise screened no up as. "
              }
            />
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="500">
            <UnitStepCard
              title={"Email Marketing"}
              img={email}
              text={
                "Our platform provides mail services that can make the customers on your email list aware of new products, discounts, and other service "
              }
            />
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="800">
            <UnitStepCard
              title={"Social & Content Engagement"}
              img={media}
              text={
                "Our platform help to attract, engage, and retain an audience by creating and sharing relevant articles, videos, podcasts, and other media. "
              }
            />
          </div>
          {/* jeriore */}
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="1100">
            <UnitStepCard
              title={"Search Engine Optimization "}
              img={search}
              text={
                "Visibility and Ranking higher in search engines can lead to an increase in traffic to a website. Let our platform handle that for you. "
              }
            />
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="1400">
            <UnitStepCard
              title={"Customizable campaigns"}
              img={campaign}
              text={
                "Our platform offers a variety of customizable campaign options, so you can tailor your marketing efforts to your specific needs and goals."
              }
            />
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="1600">
            <UnitStepCard
              title={"Comprehensive analytics"}
              img={analysis}
              text={
                "We provide in-depth analytics and reporting tools, giving you detailed insights into your campaigns' performance and allowing you to make data-driven decisions."
              }
            />
          </div>
          <Spacer height={"90px"} heightSm={"0px"} />
        </div>
      </section>
    </>
  );
};
